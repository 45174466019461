import { createReducer, on } from '@ngrx/store';

import { User } from './user.models';

import * as fromActions from './user.actions';

export interface UserState {
  entity: User;
  uid: string;
  loading: boolean;
  error: string;
}

const initialState: UserState = {
  entity: null,
  uid: null,
  loading: null,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(fromActions.Init, (state) => ({ ...state, loading: true })),
  on(fromActions.InitAuthorized, (state, { uid, user }) => ({ ...state, entity: user, uid, loading: false, error: null })),
  on(fromActions.InitUnauthorized, (state) => ({ ...state, entity: null, loading: false, error: null })),
  on(fromActions.InitError, (state, { error }) => ({ ...state, loading: false, error })),
  on(fromActions.SignInEmail, (state) => ({ ...state, loading: true })),
  on(fromActions.SignInEmailSuccess, (state, { uid, user }) => ({ ...state, entity: user, uid, loading: false, error: null })),
  on(fromActions.SignInEmailError, (state, { error }) => ({ ...state, loading: false, error })),
  on(fromActions.SignUpEmail, (state) => ({ ...state, loading: true })),
  on(fromActions.SignUpEmailSuccess, (state, { user }) => ({ ...state, uid: user.uid, entity: user, loading: false, error: null })),
  on(fromActions.SignUpEmailError, (state, { error }) => ({ ...state, loading: false, error })),
  on(fromActions.SignOut, (state) => ({ ...state, loading: true })),
  on(fromActions.SignOutSuccess, () => ({ ...initialState })),
  on(fromActions.SignOutError, (state, { error }) => ({ ...state, loading: false, error })),
  on(fromActions.Update, (state) => ({ ...state, loading: true })),
  on(fromActions.UpdateSuccess, (state, { user }) => ({ ...state, entity: { ...state.entity, ...user }, loading: false, error: null })),
  on(fromActions.UpdateError, (state, { error }) => ({ ...state, loading: false, error })),
  on(fromActions.ResetPassword, (state) => ({ ...state, loading: true })),
  on(fromActions.ResetPasswordSuccess, () => ({ ...initialState })),
  on(fromActions.ResetPasswordError, (state, { error }) => ({ ...state, loading: false, error })),
  on(fromActions.UpdateDateOfBirth, (state, { dateOfBirth }) => ({ ...state, entity: { ...state.entity, dob: dateOfBirth }, loading: false, error: null })),
  on(fromActions.PersistDateOfBirth, (state, { dateOfBirth }) => ({ ...state, entity: { ...state.entity, dob: dateOfBirth }, loading: false, error: null }))
);
