import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, filter, take } from 'rxjs';
import * as fromUser from '@app/store/user';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/store';
import * as fromDictionaries from '@app/store/dictionaries';
import { fadeAnimation } from './animations/fade.animation';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { ImagePreloadService } from '@app/services/image-preload/image-preload.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'Klipdrift #ThatsGold';

  isAuthorized$: Observable<boolean>;
  user$: Observable<fromUser.User>;
  getIsDictionaryLoading$: Observable<boolean>;

  constructor(
    private store: Store<fromRoot.State>,
    private deviceDetector: DeviceDetectorService,
    private router: Router,
    private preload: ImagePreloadService
  ) {
    if (!this.deviceDetector.isMobile()) {
      this.store.dispatch(fromUser.SignOut());
      this.router.navigate(['/auth/age-gate']);
    }
  }

  ngOnInit(): void {
    const images = [
      'assets/images/segments/Andre_Esterhuizen.png',
      'assets/images/segments/Eben_Etzebeth.png',
      'assets/images/segments/Herschel_Jantjies.png',
      'assets/images/segments/Lood_de_Jager.png',
      'assets/images/segments/RG_Snyman.png',
      'assets/images/segments/Bongi_Mbonambi.png',
      'assets/images/segments/Elton_Jantjies.png',
      'assets/images/segments/JL_du_Preez.png',
      'assets/images/segments/Lukhanyo_Am.png',
      'assets/images/segments/Siya_Kolisi.png',
      'assets/images/segments/Canan_Moodie.png',
      'assets/images/segments/Evan_Roos.png',
      'assets/images/segments/Jaden_Hendrikse.png',
      'assets/images/segments/Makazole_Mapimpi.png',
      'assets/images/segments/Steven_Kitshoff.png',
      'assets/images/segments/Cheslin_Kolbe.png',
      'assets/images/segments/Faf_de_Klerk.png',
      'assets/images/segments/Jasper_Wiese.png',
      'assets/images/segments/Malcolm_Marx.png',
      'assets/images/segments/Thomas_du_Toit.png',
      'assets/images/segments/Cobus_Reinach.png',
      'assets/images/segments/Franco_Mostert.png',
      'assets/images/segments/Jean_Kleyn.png',
      'assets/images/segments/Manie_Libbok.png',
      'assets/images/segments/Trevor_Nyakane.png',
      'assets/images/segments/Damian_Willemse.png',
      'assets/images/segments/Frans_Malherbe.png',
      'assets/images/segments/Jesse_Kriel.png',
      'assets/images/segments/Marco_van_Staden.png',
      'assets/images/segments/Vincent_Koch.png',
      'assets/images/segments/Damian_de_Allende.png',
      'assets/images/segments/Gold_Edge_Wheel.png',
      'assets/images/segments/Joseph_Dweba.png',
      'assets/images/segments/Marvin_Orie.png',
      'assets/images/segments/Willie_Le_Roux.png',
      'assets/images/segments/Deon_Fourie.png',
      'assets/images/segments/Grant_Williams.png',
      'assets/images/segments/Kurt-Lee_Arendse.png',
      'assets/images/segments/Ox_Nche.png',
      'assets/images/segments/wheel-pin.svg',
      'assets/images/segments/Duane_Vermeulen.png',
      'assets/images/segments/Handre_Pollard.png',
      'assets/images/segments/Kwagga_Smith.png',
      'assets/images/segments/Pieter-Steph_du_Toit.png',
    ];

    //THIS WAS DRIVING BANDWIDTH COSTS THROUGH THE ROOF, EVEN PLAYERS WHO JUST REGISTERING WAS DOWNLOADING ALL THESE ASSETS
    //this.preload.preloadImages(images);
    this.initSelectors();
    this.initDispatchers();
  }

  ngOnDestroy(): void {}

  private initSelectors(): void {
    this.isAuthorized$ = this.store.pipe(select(fromUser.getIsAuthorized));
    this.user$ = this.store.pipe(select(fromUser.getUser));

    this.getIsDictionaryLoading$ = this.store.pipe(select(fromDictionaries.getLoading));

    this.store
      .pipe(select(fromUser.getUserState))
      .pipe(
        filter((state) => !!state.uid),
        take(1)
      )
      .subscribe((state) => this.store.dispatch(fromDictionaries.Read()));
  }

  private initDispatchers(): void {
    this.store.dispatch(fromUser.Init());
  }

  onSignOut(): void {
    this.store.dispatch(fromUser.SignOut());
  }
}
