import { createSelector, createFeatureSelector } from '@ngrx/store';
import { DictionariesState } from './dictionaries.reducer';

export const getDictionariesState = createFeatureSelector<DictionariesState>('dictionaries');

export const getDictionaries = createSelector(getDictionariesState, (state) => state.entities);
export const getLoading = createSelector(getDictionariesState, (state) => state.loading);
export const getIsReady = createSelector(getDictionariesState, (state) => state.entities && !state.loading);

export const getTeams = createSelector(getDictionaries, (dictionaries) => dictionaries && dictionaries.teams);
export const getPlayers = createSelector(getDictionaries, (dictionaries) => dictionaries && dictionaries.players);
export const getPrizes = createSelector(getDictionaries, (dictionaries) => dictionaries && dictionaries.prizes);

export const getPlayerById = (id: string) => createSelector(getPlayers, (players) => players && players.items.find((x) => x.id === id));

export const getPlayerSocialImage = (id: string, platform: 'facebook' | 'instagram') =>
  createSelector(getPlayers, (players) => {
    const imageUrl = players && players.items.find((x) => x.id === id)?.socialImage[platform];
    if (!imageUrl) return { path: '', name: '' };

    const parts = imageUrl.split('/');
    const filename = parts.pop();
    if (!filename) return { path: '', name: '' };

    const fileParts = filename.split('.');
    const extension = fileParts.pop();
    const name = fileParts.join('.');
    return {
      path: imageUrl,
      name: name + (extension ? '.' + extension : ''),
    };
  });

export const getTeamById = (id: string) => createSelector(getTeams, (teams) => teams && teams.items.find((x) => x.id === id));
