import { ActionReducerMap } from '@ngrx/store';

import { UserEffects } from './user/user.effects';
import { DictionariesEffects } from '@app/store/dictionaries';

import * as fromUser from './user/user.reducer';
import * as fromDictionaries from './dictionaries/dictionaries.reducer';

export interface State {
  user: fromUser.UserState;
  dictionaries: fromDictionaries.DictionariesState;
}

export const reducers: ActionReducerMap<State> = {
  user: fromUser.reducer,
  dictionaries: fromDictionaries.reducer,
};

export const effects = [UserEffects, DictionariesEffects];
