import { inject } from '@angular/core';
import { CanMatchFn, Route, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { tap, take, filter, map } from 'rxjs/operators';
import * as fromRoot from '@app/store';
import { of } from 'rxjs';
import * as fromUser from '@app/store/user';

export const authGuard: CanMatchFn = (route: Route) => {
  const store = inject(Store) as Store<fromRoot.State>;
  const router = inject(Router) as Router;

  return store.pipe(
    select(fromUser.getUserState),
    // tap((x) => console.log('authGuard', x)),
    filter((state) => !state.loading),
    take(1),
    tap((state) => {
      // console.log('authGuard');

      if (!state.uid) {
        router.navigate(['auth/age-gate']);
      }
    }),
    map((state) => !!state.uid)
  );
};
