import { Injectable } from '@angular/core';

@Injectable()
export class ImagePreloadService {
  preloadImages(images: string[]): Promise<any[]> {
    return Promise.all(images.map((image) => this.preloadImage(image)));
  }

  preloadImage(src: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(src);
      img.onerror = (err) => reject(err);
    });
  }
}
