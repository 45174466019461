export const environment = {
  production: true,
  name: 'prod',
  firebase: {
    apiKey: 'AIzaSyABAjGv0JkD2ho7L8KztLvflcAgIHm2tqQ',
    authDomain: 'triswick-live-sports.firebaseapp.com',
    projectId: 'triswick-live-sports',
    storageBucket: 'triswick-live-sports.appspot.com',
    messagingSenderId: '821274596831',
    appId: '1:821274596831:web:3e8581c235968b65df606a',
  },
};
