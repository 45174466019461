import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, from, of } from 'rxjs';
import { createEffect } from '@ngrx/effects';
import { Actions, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap, take, withLatestFrom, distinctUntilKeyChanged } from 'rxjs/operators';
import { Auth, user, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail } from '@angular/fire/auth';
import { deleteDoc, doc, docData, Firestore, getDoc, serverTimestamp, setDoc, updateDoc } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { User } from './user.models';
import { getUser } from '@app/store/user/user.selectors';
import { NotificationService } from '@app/services/notification/notification.service';
import * as Sentry from '@sentry/browser';
import * as fromActions from './user.actions';
import * as fromRoot from '@app/store';
import * as fromUser from '@app/store/user/user.selectors';

@Injectable()
export class UserEffects {
  constructor(
    private actions: Actions,
    private router: Router,
    private afAuth: Auth,
    private firestore: Firestore,
    private store: Store<fromRoot.State>,
    private notification: NotificationService
  ) {}

  init$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.Init),
      switchMap(() => user(this.afAuth).pipe(take(1))),
      switchMap((authState) => {
        if (authState) {
          return from(docData(doc(this.firestore, `users/${authState.uid}`))).pipe(
            distinctUntilKeyChanged('poolId'),
            map((userDoc) => {
              const user = userDoc ? (userDoc as User) : null;
              if (user) {
                Sentry.setUser({ id: authState.uid, email: user.email, username: user.username });
              }
              return fromActions.InitAuthorized({ uid: authState.uid, user: user });
            }),
            catchError((err) => of(fromActions.InitError(err.message)))
          );
        } else {
          return of(fromActions.InitUnauthorized());
        }
      })
    )
  );

  signInEmail$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.SignInEmail),
      switchMap(({ credentials }) =>
        from(signInWithEmailAndPassword(this.afAuth, credentials.email.trim(), credentials.password.trim())).pipe(
          switchMap((signInState) =>
            from(getDoc(doc(this.firestore, `users/${signInState.user.uid}`))).pipe(
              take(1),
              tap(() => {
                this.router.navigate(['lobby']);
              }),
              map((user) => fromActions.SignInEmailSuccess({ uid: signInState.user.uid, user: user.data() as User }))
            )
          ),
          catchError((err) => {
            const error = err.message.includes('auth/wrong-password')
              ? 'Wrong password.'
              : err.message.includes('auth/user-not-found')
              ? 'User not found.'
              : err.message;

            this.notification.error(error);
            return of(fromActions.SignInEmailError(err.message));
          })
        )
      )
    )
  );

  signUpEmail$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.SignUpEmail),
      switchMap(({ credentials, data }) =>
        from(getDoc(doc(this.firestore, `unique-mobiles/${data.mobile.toString()}`))).pipe(
          map((doc) => {
            if (doc.exists()) {
              const error = 'Mobile number already in use.';
              this.notification.error(error);
              return { proceed: false, action: fromActions.SignUpEmailError({ error }), credentials, data };
            }
            return { proceed: true, credentials, data };
          }),
          catchError((err) => {
            const error = err.message.includes('Missing or insufficient permissions') ? 'Mobile number already in use.' : err.message;
            this.notification.error(error);
            return of({ proceed: false, action: fromActions.SignUpEmailError({ error }), credentials, data });
          })
        )
      ),
      switchMap((result: { proceed: boolean; action?: any; credentials: any; data: any }) => {
        if (!result.proceed) {
          return of(result.action);
        }
        return from(createUserWithEmailAndPassword(this.afAuth, result.credentials.email.trim(), result.credentials.password.trim())).pipe(
          map((state) => ({
            user: {
              ...result.data,
              uid: state.user.uid,
              email: state.user.email,
              username: result.data.username,
              usernameBanned: false,
              created: serverTimestamp(),
            },
            data: result.data,
          })),
          switchMap(({ user, data }) =>
            forkJoin([
              from(setDoc(doc(this.firestore, `users/${user.uid}`), user)),
              from(setDoc(doc(this.firestore, `unique-mobiles/${data.mobile.toString()}`), { placeholder: true })),
            ]).pipe(
              map(() => fromActions.SignUpEmailSuccess({ user })),
              tap(() => this.router.navigate(['lobby'])),
              catchError((err) => {
                const error = err.message.includes('auth/email-already-in-use') ? 'Email already in use.' : err.message;
                this.notification.error(error);
                return of(fromActions.SignUpEmailError({ error: err.message }));
              })
            )
          )
        );
      })
    )
  );

  signOut$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.SignOut),
      switchMap(() =>
        from(signOut(this.afAuth)).pipe(
          tap(() => this.router.navigate(['auth'])),
          map(() => fromActions.SignOutSuccess()),
          catchError((err) => of(fromActions.SignOutError(err.message)))
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.ResetPassword),
      switchMap(({ email }) =>
        from(sendPasswordResetEmail(this.afAuth, email)).pipe(
          tap(() => this.notification.success('Password reset email sent.')),
          map(() => fromActions.ResetPasswordSuccess()),
          catchError((err) => {
            const error = err.message.includes('auth/user-not-found') ? 'User not found.' : err.message;
            this.notification.error(error);

            return of(fromActions.ResetPasswordError(err.message));
          })
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.Update),
      withLatestFrom(this.store.select(getUser)),
      switchMap(([{ user }, existingUser]) => {
        if (existingUser.mobile !== user.mobile) {
          return from(deleteDoc(doc(this.firestore, `unique-mobiles/${existingUser.mobile.toString()}`))).pipe(
            switchMap(() => from(setDoc(doc(this.firestore, `unique-mobiles/${user.mobile.toString()}`), { placeholder: true }))),
            map(() => ({ proceed: true, user })),
            catchError((err) => {
              if (err.message.includes('Missing or insufficient permissions')) {
                const error = 'Mobile number already in use.';
                this.notification.error(error);
                return of({ proceed: false, action: fromActions.UpdateError({ error }), user });
              }
              return of({ proceed: false, action: fromActions.UpdateError(err.message), user });
            })
          );
        } else {
          return of({ proceed: true, user });
        }
      }),
      switchMap((result: { proceed: boolean; action?: any; user: any }) => {
        if (!result.proceed) {
          return of(result.action);
        }
        return from(updateDoc(doc(this.firestore, `users/${this.afAuth.currentUser.uid}`), { ...result.user, email: this.afAuth.currentUser.email })).pipe(
          tap(() => {
            this.notification.success('Your profile has been updated.');
          }),
          map(() => fromActions.UpdateSuccess({ user: result.user })),
          catchError((err) => of(fromActions.UpdateError(err.message)))
        );
      })
    )
  );

  persistDateOfBirth$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.PersistDateOfBirth),
      withLatestFrom(this.store.select(fromUser.getUser)),
      switchMap(([action, user]) => {
        const { dateOfBirth } = action;
        const { uid } = user;
        return from(updateDoc(doc(this.firestore, `users/${uid}`), { ...user, dob: dateOfBirth })).pipe(
          map(() => fromActions.UpdateSuccess({ user: { ...user, dob: dateOfBirth } })),
          catchError((err) => of(fromActions.UpdateError(err.message)))
        );
      })
    )
  );
}
