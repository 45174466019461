import { createReducer, on } from '@ngrx/store';

import { Dictionaries } from './dictionaries.models';

import * as fromActions from './dictionaries.actions';

export interface DictionariesState {
  entities: Dictionaries;
  loading: boolean;
  error: string;
}

const initialState: DictionariesState = {
  entities: null,
  loading: null,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(fromActions.Read, (state) => ({ ...state, loading: true, error: null })),
  on(fromActions.ReadSuccess, (state, { dictionaries }) => ({ ...state, entities: dictionaries, loading: false })),
  on(fromActions.ReadError, (state, { error }) => ({ ...state, entities: null, loading: false, error }))
);
