import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { collection, getDocs, DocumentSnapshot, Firestore } from '@angular/fire/firestore';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { zip, of, from } from 'rxjs';

import { Dictionaries, Dictionary, Item, ControlItem } from './dictionaries.models';
import * as fromActions from './dictionaries.actions';

const documentToItem = (x: DocumentSnapshot<any>): Item => {
  const data = x.data();
  return { id: x.id, ...data };
};

const itemToControlItem = (x: Item): ControlItem => ({
  value: x.id,
  label: x.name,
});

const addDictionary = (items: Item[]): Dictionary => ({
  items,
  controlItems: [...items].map((x) => itemToControlItem(x)),
});

@Injectable()
export class DictionariesEffects {
  constructor(private actions: Actions, private firestore: Firestore) {}

  read = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.Read),
      switchMap(() =>
        zip(
          from(getDocs(collection(this.firestore, 'teams'))).pipe(
            take(1),
            map((snapshot) => snapshot.docs.map((x) => documentToItem(x)))
          ),
          from(getDocs(collection(this.firestore, 'players'))).pipe(
            take(1),
            map((snapshot) => snapshot.docs.map((x) => documentToItem(x)))
          ),
          from(getDocs(collection(this.firestore, 'prizes'))).pipe(
            take(1),
            map((snapshot) => snapshot.docs.map((x) => documentToItem(x)))
          )
        ).pipe(
          map(([teams, players, prizes]) => {
            const dictionaries: Dictionaries = {
              teams: addDictionary(teams),
              players: addDictionary(players),
              prizes: addDictionary(prizes),
            };
            return fromActions.ReadSuccess({ dictionaries });
          }),
          catchError((error) => of(fromActions.ReadError({ error: error.message })))
        )
      )
    )
  );
}
