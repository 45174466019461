import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@app/guards/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'lobby',
        loadChildren: () => import('./pages/lobby/lobby.module').then((m) => m.LobbyModule),
        canMatch: [authGuard],
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'auth',
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/auth/age-gate',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
