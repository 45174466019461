import { Injectable } from '@angular/core';

@Injectable()
export class FacebookService {
  private clipboardBlobRef: Blob | null = null;

  constructor() {}

  shareToFacebook(matchId: string, playerId: string) {
    const id = '752492563350238';
    const link = encodeURIComponent(`https://klipdriftthatsgold.co.za/social/${playerId}`);
    const redirect = encodeURIComponent(`https://klipdriftthatsgold.co.za/lobby/profile/${matchId}/${playerId}?fb=true`);
    const url = `https://www.facebook.com/dialog/share?app_id=${id}&display=popup&href=${link}&redirect_uri=${redirect}`;

    try {
      const w = window as any;
      w.location.href = url;
    } catch (e) {
      console.log(e);
    }
  }

  async shareToInstagram(image: { path: string; name: string }) {
    try {
      this.clipboardBlobRef = await this.fetchImageAsBlob(image.path);

      if (!this.clipboardBlobRef) return;

      const shareData = {
        files: [
          new File([this.clipboardBlobRef], image.name, {
            type: 'image/png',
            lastModified: Date.now(),
          }),
        ],
      };

      if (navigator.canShare && navigator.canShare(shareData)) {
        return await navigator.share(shareData);
      } else {
        throw new Error('Web Share API is not supported in this browser.');
      }
    } catch (e) {
      throw e;
    }
  }

  private async fetchImageAsBlob(url: string): Promise<Blob> {
    const res = await fetch(url);
    if (!res.ok) throw new Error('Network response was not ok.');
    return await res.blob();
  }
}
