import { createAction, props } from '@ngrx/store';

import { User, EmailPasswordCredentials, UserCreateRequest } from './user.models';

export const Init = createAction('[User] Init: Start');
export const InitAuthorized = createAction('[User] Init: Authorized', props<{ uid: string; user: User }>());
export const InitUnauthorized = createAction('[User] Init: Unauthorized');
export const InitError = createAction('[User] Init: Error', props<{ error: string }>());

export const UpdateDateOfBirth = createAction('[User] Update Date of Birth', props<{ dateOfBirth: string }>());
export const PersistDateOfBirth = createAction('[User] Persist Date of Birth', props<{ dateOfBirth: string }>());

export const SignInEmail = createAction('[User] Sign In with email: Start', props<{ credentials: EmailPasswordCredentials }>());
export const SignInEmailSuccess = createAction('[User] Sign In with email: Success', props<{ uid: string; user: User }>());
export const SignInEmailError = createAction('[User] Sign In with email: Error', props<{ error: string }>());

export const SignUpEmail = createAction('[User] Sign Up with email: Start', props<{ credentials: EmailPasswordCredentials; data: UserCreateRequest }>());
export const SignUpEmailSuccess = createAction('[User] Sign Up with email: Success', props<{ user: User }>());
export const SignUpEmailError = createAction('[User] Sign Up with email: Error', props<{ error: string }>());

export const SignOut = createAction('[User] Sign Out: Start');
export const SignOutSuccess = createAction('[User] Sign Out: Success');
export const SignOutError = createAction('[User] Sign Out: Error', props<{ error: string }>());

export const Update = createAction('[User] Update: Start', props<{ user: Partial<User> }>());
export const UpdateSuccess = createAction('[User] Update: Success', props<{ user: User }>());
export const UpdateError = createAction('[User] Update: Error', props<{ error: string }>());

export const ResetPassword = createAction('[User] Reset Password: Start', props<{ email: string }>());
export const ResetPasswordSuccess = createAction('[User] Reset Password: Success');
export const ResetPasswordError = createAction('[User] Reset Password: Error', props<{ error: string }>());

export const DetachFirestoreListeners = createAction('[User] Detach Firestore Listeners');
